<template>
  <div class="reminder">
    <div class="row justify-content-start align-items-center my-10">
      <div class="col-12">Toplam {{ totalCount }} hatırlatma bulundu.</div>
    </div>
    <div
      class="row d-flex align-items-center justify-content-between reminder-bar"
    >
      <div class="d-flex align-items-center col-md-3 col-12">
        <div class="w-full mt-4" style="width: 100%">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <div class="col mt-4">
          <b-form-group>
            <b-dropdown
              id="dropdown-action-table"
              variant="outline-light"
              text="Tarihe Göre Sırala"
              class="mr-2"
            >
              <b-dropdown-item>Tarihe Göre Sırala</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Aktife Çek</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Kaldır</b-dropdown-item>
              <b-dropdown-item>Seçilenleri Dışa Aktar</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </div>
        <div class="col mt-4">
          <b-button variant="primary" :to="{ name: 'hatirlatma-ekle' }"
            >+ Yeni Ekle</b-button
          >
        </div>
      </div>
    </div>
    <div class="row mt-8">
      <div
        class="col-xl-3 col-lg-6 col-12 mt-4"
        v-for="reminder in reminders"
        :key="reminder.id"
      >
        <div class="card dveb-card-white reminder-card position-relative">
          <span :class="reminder.status" class="color-bar"></span>
          <div class="card-body">
            <div
              class="d-flex justify-content-between align-items-center card-header-title"
            >
              <h2>{{ reminder.title }}</h2>
              <span><i class="flaticon-more-v2"></i></span>
            </div>
            <p class="card-content">{{ reminder.description }}</p>
            <div
              class="d-flex justify-content-between align-items-end card-image-footer"
            >
              <div class="d-flex">
                <span>{{ reminder.startdate }}</span>
                <span v-if="reminder.enddate" class="mx-4">-</span>
                <span v-if="reminder.enddate">{{ reminder.enddate }}</span>
              </div>
              <div class="image-area">
                <img
                  v-for="images in reminder.images"
                  :src="`/image/${images}`"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'reminders-list',
  data() {
    return {
      search: '',
      reminders: [],
      totalCount: 0,
    };
  },
  created() {
    this.setList();
  },

  methods: {
    setList() {
      this.reminders = [
        {
          id: 1,
          status: 'onemli',
          title: 'Refik Bey kira ödemesi gecikti.',
          description: 'Kira ödemesi yapacak.',
          startdate: '01.07.2023',
          enddate: false,
          images: ['image-150.png', 'image-150.png'],
        },
        {
          id: 2,
          status: 'normal',
          title: 'Refik Selim Tesisatçı talebi.',
          description:
            'Su tesisatında arıza olduğu için tesisatçı çağrıldı. 3 gün içinde bitireceğini söyledi....',
          startdate: '01.07.2023',
          enddate: '04.07.2023',
          images: ['image-150.png', 'image-150.png', 'image-150.png'],
        },
        {
          id: 3,
          status: 'az-onemli',
          title: 'Bünyamin Bey, tesisat gideri.',
          description:
            'Refik Bey’in talebi sonucu, tesisatçıya ödeme yapılacak... 5000₺',
          startdate: '04.07.2023',
          enddate: false,
          images: ['image-150.png', 'image-150.png'],
        },
        {
          id: 4,
          status: 'onemli',
          title: 'Refik Bey kira ödemesi gecikti.',
          description: 'Kira ödemesi yapacak. ',
          startdate: '01.07.2023',
          enddate: false,
          images: ['image-150.png', 'image-150.png'],
        },
        {
          id: 5,
          status: 'normal',
          title: 'Refik Bey kira ödemesi gecikti.',
          description: 'Kira ödemesi yapacak. ',
          startdate: '01.07.2023',
          enddate: false,
          images: ['image-150.png', 'image-150.png'],
        },
      ];
      this.totalCount = this.reminders.length;
    },
    searchInList() {
      this.setList();
      const searchValue = this.search;
      const foundItems = this.reminders.filter((item) => {
        for (const prop in item) {
          if (item[prop].toString().toLowerCase().includes(searchValue)) {
            return true;
          }
        }
        return false;
      });
      this.reminders = foundItems;
      this.totalCount = foundItems.length;
    },
  },
  watch: {
    search: function () {
      this.searchInList();
    },
  },
};
</script>
<style lang="scss" scope>
.reminder {
  fieldset {
    margin-bottom: 0 !important;

    input {
      border: none !important;
      padding: 1.5rem !important;
      border-radius: 0.76923rem;

      &:focus {
        border: 1px solid $dveb-info !important;
        background-color: #fff !important;
      }
    }
  }

  .btn.btn-outline-light:not(:disabled):not(.disabled):active:not(.btn-text),
  .btn.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-light.dropdown-toggle,
  .show .btn.btn-outline-light.btn-dropdown {
    background-color: #fff;
  }

  .btn {
    border-radius: 10px !important;
    padding: 0.77rem 2rem;
  }

  .reminder-card {
    height: 100%;
    .color-bar {
      height: 1.46154rem;
      border-radius: 0.73077rem 0.73077rem 0rem 0rem;
      &.onemli {
        background-color: rgba(255, 91, 91, 0.5);
      }
      &.normal {
        background-color: rgba(255, 186, 64, 0.5);
      }
      &.az-onemli {
        background-color: rgba(64, 51, 255, 0.5);
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;

      padding: 0.62rem !important;

      .card-header-title {
        h2 {
          font-size: 1.07692rem;
          line-height: 1.69231rem;
          font-weight: 600;
        }

        i {
          font-size: 1.84615rem !important;
          color: $dveb-primary;
        }
      }

      .card-content {
        margin-top: 1.08rem;
        margin-bottom: 1.69rem;
        color: rgba($color: $dveb-primary, $alpha: 0.5);
      }

      .card-image-footer {
        margin-top: auto;
        span {
          font-size: 0.92308rem;
          color: rgba($color: $dveb-primary, $alpha: 0.5);
          line-height: 1.53846rem;
        }

        .image-area {
          display: flex;
          justify-content: flex-end;
          img {
            width: 3.07692rem;
            height: 3.07692rem;
            border-radius: 50%;
            border: 2px solid #3f98ff;
            margin-left: -1.5rem;
          }
        }
      }
    }
  }
}
</style>
